body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  color: #fff;
  background-color: #121212;
  scroll-behavior: smooth;
}
header, footer {
  background-color: #000;
  color: white;
  padding: 1rem 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}
nav a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}
nav a:hover {
  text-decoration: underline;
  color: #0056b3;
}
.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.3);
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}


.hero {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.hero video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

.hero-content {
  z-index: 1;
  animation: fadeIn 2s ease-in-out;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.hero a {
  padding: 1rem 2rem;
  background-color: #004a99;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.hero a:hover {
  background-color: #003366;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}





section {
  padding: 4rem 2rem;
  text-align: center;
}
.section-title {
  font-size: 3.5rem;
  margin-bottom: 2rem;
}
.container {
  max-width: 800px;
  margin: 0 auto;
  color: #fff;
}
.card {
  background: #292929;
  border: none;
  border-radius: 0px;
  margin-bottom: 2rem;
  padding: 1.5rem;
  height: 100%;
  transition: transform 0.3s ease;
}
.card:hover {
  transform: translateY(-10px);
}
.card-body {
  color: #fff;
}
.card-title {
  font-weight: bold;
  margin-bottom: 1rem;
}
#services .card {
  width: 100%;
}
.testimonials {
  background: #1e1e1e;
  padding: 4rem 2rem;
}
.testimonials h2 {
  font-size: 2.5rem;
  text-align: left;
}
.carousel-item {
  margin-bottom: 2rem;
  height: 300px;
}
.carousel-item .card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 2rem;
}
.carousel-indicators {
  bottom: -30px;
}
.carousel-indicators li {
  background-color: #004a99;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.carousel-indicators .active {
  background-color: #00aaff;
}
.process {
  background: #1e1e1e;
  padding: 4rem 2rem;
}
.process h2 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
}
.step {
  margin-bottom: 4rem;
  text-align: center;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}
.step h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.step p {
  font-size: 1rem;
  max-width: 500px;
  margin: 0 auto;
}
.step::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background: #0056b3;
  margin: 1.5rem auto;
}






/* Custom styles for form controls */
.custom-form .form-label {
  display: block;
  text-align: left;
  color: #aaa;
  margin-bottom: 0.5rem;
}

.custom-form .form-control, 
.custom-form .form-select {
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.custom-form .form-control:focus, 
.custom-form .form-select:focus {
  background-color: #333;
  color: #fff;
  border: 1px solid #777;
}

.custom-form .form-select.is-invalid {
  border-color: #ff6b6b;
  padding-right: 2.25rem; /* Adjust padding to accommodate the invalid icon */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1.5em 1.5em;
}

.custom-form .invalid-feedback {
  color: #ff6b6b !important;
  text-align: left !important;
  display: block !important;
  margin-left: 0 !important;
}

/* Prevent textarea from being resizable */
textarea {
  resize: none !important;
}






/* Custom button styles */
.custom-btn {
  background-color: #004a99;
  color: white;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.custom-btn:hover {
  background-color: #003366;
  transform: translateY(-2px);
}

.custom-btn:active {
  background-color: #002244;
  transform: translateY(0);
}





@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.sr {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}
.sr-visible {
  opacity: 1;
  transform: none;
}
.form-alert {
  margin-top: 20px;
  animation: fadeInDown 0.5s;
}

.service-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #004a99;
}
